// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// require("@rails/ujs").start()
window.jQuery = window.$ = require("jquery")
window.moment = require("moment")
require('jquery-ui');
require("turbolinks").start();
// require("@rails/activestorage").start();
// require("channels")

//--- Bootstrap
import 'bootstrap';

require("@fortawesome/fontawesome-free");
require("daterangepicker/daterangepicker");

// class_calendar - new
require("modernizr/modernizr.custom.js");